.register {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .register-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #F2F2F2;
    padding: 30px;
    height: 100vh
  }
  
  .register-textBox {
    border-radius: 6px;
    padding: 6px;
    vertical-align: middle;
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .register-btn {
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: #F26A32;
    border-radius: 6px;
  }
  
  .register-google {
    background-color: #4285f4;
  }
  
  .register div {
    margin-top: 7px;
  }
  