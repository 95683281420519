.search {
   flex: 0.9 1;
    background-color: #323232;
    /* width: 250px;
    height: 30px; */
    margin-top: 1px;
}
.search-bar {
    display: flex;
    justify-content: start;
    align-items: center;
    /* border-color: transparent; */
    border-radius: 0.3rem;
    width: 560px;
    height: 40px;
    padding-left: 0.9rem;
    background-color: #323232;
    color: white;
    opacity: 78%;
    border-color: #e19214;
    border: 2px solid #e19214;
    box-shadow: 0px 1px 6px 1px rgb(82, 50, 5);
  }
  